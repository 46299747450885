import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Texts" />
        <p>
          No blog posts found. It seems like this is still work in progress.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Texts" />
      <div className="bread-crumb"><Link to="/">&#47;Texts</Link></div>
      <div className="index-wrapper">
        <div className="index-title-area">
          <div className="index-title-text-box">
            <p className="index-title-description">
              <a href="https://www.swyx.io/learn-in-public/"> Learning in public</a> by writing texts I wish I found in the past.</p>
          </div>
        </div>
      <ol className="post-list-area" style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <li key={post.fields.slug} 
                >
              <article
                className="post-list-item"
                itemType="http://schema.org/Article"
              >
                <header 
                  className="post-list-header"
                  >
                  <h2>
                    <Link to={post.fields.slug} itemProp="url">
                      <span className="post-list-header-text" itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <p className="text__secondary">{post.frontmatter.date}</p>
                </header>
                <section 
                  className="column"
                  // className="post-list-description"
                  >
                <Link to={post.fields.slug} itemProp="url">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                      }}
                    itemProp="description" 
                    />
                    </Link>
                  
                </section>
              </article>
            </li>
          )
        })}
      </ol>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(blog)/"}}, sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }  
  }
`

